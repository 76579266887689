<template>
  <div>
    <v-card elevation="0" outlined :loading="requestProcessing">
      <template slot="progress">
        <v-progress-linear height="10" indeterminate></v-progress-linear>
        <p class="mt-4 text-center">Processing...</p>
      </template>
      <v-card-title v-if="!requestProcessing">
        View Message
      </v-card-title>
      <v-card-text class="mt-3" v-if="!requestProcessing">
        <v-container>
          <v-row>
            <v-col cols="2">
              From:
            </v-col>
            <v-col cols="10">
              {{ message.sender.fullName }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              To:
            </v-col>
            <v-col cols="10">
              {{ message.recipients.$values.map(el => el.fullName).join(";") }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              Action Required:
            </v-col>
            <v-col cols="10">
              {{ message.isActionRequired ? "Yes" : "No" }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              Subject:
            </v-col>
            <v-col cols="10">
              {{ message.subject }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              Message:
            </v-col>
            <v-col cols="10">
              {{ message.body }}
            </v-col>
          </v-row>
          <v-row
            v-if="message.attachments && message.attachments.$values.length > 0"
          >
            <v-col cols="2">
              Attachments:
            </v-col>
            <v-col cols="10">
              <div
                v-for="(item, i) in message.attachments.$values"
                :key="'o' + i"
              >
                <a :href="item.fileUrl">{{ item.fileName }}</a>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-btn
              class="mx-1"
              color="orange-bg white--text"
              @click="showMessageForm('reply')"
            >
              Reply
            </v-btn>
            <v-btn
              class="mx-1"
              color="orange-bg white--text"
              @click="showMessageForm('replyAll')"
            >
              Reply All
            </v-btn>
            <v-btn
              class="mx-1"
              color="orange-bg white--text"
              @click="showMessageForm('forward')"
            >
              Forward
            </v-btn>
          </v-row>
        </v-container>

        <v-container
          v-for="(item, i) in message.replies.$values"
          :key="'c' + i"
        >
          <hr style="background-color: #df6526; height: 1px;" />
          <v-row>
            <v-col cols="2">
              From:
            </v-col>
            <v-col cols="10" class="green--text">
              {{ item.sender.fullName }}
              {{ formatDate(item.created) }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              Subject:
            </v-col>
            <v-col cols="10">
              {{ item.subject }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              Message:
            </v-col>
            <v-col cols="10">
              {{ item.body }}
            </v-col>
          </v-row>
        </v-container>

        <v-container>
          <hr style="background-color: #df6526; height: 1px;" />
        </v-container>
        <v-card
          elevation="0"
          outlined
          color="#f1f1f1"
          v-if="needMessageForm"
          :loading="formLoading"
        >
          <v-card-text>
            <v-form>
              <v-container>
                <v-row>
                  <!-- <v-col cols="12" md="1">
                
              </v-col> -->
                  <v-col cols="12" md="6">
                    <!-- <h6 class="font-weight-bold">{{ sendToTitle }}</h6> -->
                    <v-select
                      v-model="sendTo"
                      :items="sendToItems"
                      item-text="name"
                      item-value="value"
                      label="Choose group..."
                    ></v-select>
                    <v-autocomplete
                      :loading="formLoading"
                      v-model="newMessage.sendTo"
                      v-if="showSendToLine"
                      :items="recipients"
                      item-text="name"
                      item-value="id"
                      dense
                      chips
                      small-chips
                      label="Send To"
                      multiple
                      solo
                      :required="showSendToLine"
                    ></v-autocomplete>
                    <v-container v-if="showSendByBranch">
                      <v-autocomplete
                        v-model="newMessage.sendToBranch"
                        :items="branches"
                        dense
                        chips
                        small-chips
                        label="Send To Branch"
                        multiple
                        solo
                        :required="showSendByBranch"
                      ></v-autocomplete>
                    </v-container>
                    <!-- <v-text-field
                  v-if="showSendToLine()"
                  v-model="newMessage.sendTo"
                  label="Send To"
                  required
                ></v-text-field> -->
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="newMessage.subject"
                      label="Subject"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-textarea
                      v-model="newMessage.body"
                      label="Describe Service Request"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4">
                    <div
                      v-for="attachment in attachmentsObj"
                      cols="12"
                      class="darggable-col"
                      :key="attachment.id"
                    >
                      <MediaUploader
                        class="pb-5"
                        uploadType="file"
                        :key="attachment.id"
                        :id="`multiFilePost_${attachment.id}`"
                        :index="attachment.id"
                        :accept="accept"
                        :multiple="true"
                        :extensions="extensions"
                        :sizeImage="10485760"
                        :sizeVideo="104857600"
                        :fileId.sync="attachment.fileId"
                        :file-url.sync="attachment.url"
                        :isUploading.sync="isFileUploading"
                        @onMultipleUploaded="onMultipleAddFile"
                        @onChange="onChange"
                        @onDeleteFile="onDeleteFile"
                      />
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-radio-group v-model="newMessage.isActionRequired" row>
                      <template v-slot:label>
                        <div>Action Required:</div>
                      </template>
                      <v-radio label="Yes" value="1"></v-radio>
                      <v-radio label="No" value="0"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-btn color="orange-bg white--text" @click="sendMessage">
                  Send Message
                </v-btn>
                <v-btn class="mx-2" @click="cancelMessage">
                  Cancel
                </v-btn>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// import { GET_PAGED, POST_DATA, API_USERS } from "@/core/store/crud.module";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import {
  GET_QUERY,
  POST_DATA,
  API_EMPLOYEES,
  API_CUSTOMERS,
  API_MESSAGES
} from "@/core/store/crud.module";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import MediaUploader from "@/view/components/imageUploader/mediaUploader.vue";
import permissionsHelper from "@/core/helpers/permissionsHelper";
import dateFormatter from "@/core/helpers/dateFormatter";
import stringFormatter from "@/core/helpers/stringFormatter";

export default {
  mixins: [validationMixin],
  components: {
    MediaUploader
  },
  data: () => ({
    isLoading: false,
    formLoading: false,
    message: {
      sendTo: [],
      sendToBranch: null,
      messageSubject: "",
      messageBody: "",
      attachments: [],
      isActionRequired: "0"
    },
    needMessageForm: false,
    replyType: "",
    newMessage: {
      sendTo: [],
      sendToBranch: [],
      recipients: null,
      subject: "",
      body: "",
      attachments: [],
      isActionRequired: "0"
    },
    showSendToLine: true,
    showSendByBranch: false,
    sendTo: "YN team member or customer",
    sendToItems: [
      {
        name: "YN team member or customer",
        value: "YN team member or customer"
      },
      {
        name: "My YN Team and my customers",
        value: "My YN Team and my customers"
      },
      { name: "My YN Team", value: "My YN Team" },
      { name: "My customers", value: "My customers" },
      { name: "Send by branch", value: "Send by branch" }
    ],
    recipients: [],
    ynTeamMembers: [],
    customers: [],
    branches: [],
    attachmentsObj: [{ id: 1, url: null }],
    isFileUploading: false,
    loadingRecipients: false,
    requestProcessing: false
  }),
  props: {
    messageId: {
      type: [Number, String],
      default: 0
    }
  },
  validations() {
    return {
      sendTo: {
        required
      },
      newMessage: {
        subject: {
          required
        },
        body: {
          required
        },
        recipients: {
          required,
          minLength: 1
        }
      }
    };
  },
  watch: {
    // userQuery: async function() {
    //   if (this.userQuery && this.userQuery.length > 0) {
    //     await this.getUsers();
    //   } else {
    //     this.users = [];
    //   }
    // },
    // "options.sortBy": async function() {
    //   await this.getDataFromApi();
    //   // if (this.userQuery && this.userQuery.length > 0) {
    //   //   await this.getUsers();
    //   // } else {
    //   //   this.users = [];
    //   // }
    // },
  },
  async created() {
    permissionsHelper.getPermissions().then(this.getPermissions);
    await this.getMessage();
  },
  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Message Center", route: "/message-center" },
      { title: "View Message" }
    ]);

    this.isLoading = false;
  },
  methods: {
    cancelMessage() {
      this.needMessageForm = false;
      this.replyType = "";

      this.newMessage = {
        sendTo: [],
        sendToBranch: [],
        recipients: null,
        subject: "",
        body: "",
        attachments: [],
        isActionRequired: "0"
      };
    },
    showMessageForm(type) {
      this.needMessageForm = true;

      this.replyType = type;
      this.newMessage.subject = this.message.subject;
      this.newMessage.body = ""; //this.message.body;
      this.newMessage.sendTo = [];

      this.formLoading = true;
      let self = this;
      Promise.allSettled([
        self.getRecipientsPromise(),
        self.getCustomersPromise()
      ]).then(function(res) {
        let recItems = res[0].value.data.map(el => {
          return { id: el.id, name: el.fullName };
        });
        let custItems = res[1].value.data.$values.map(el => {
          return {
            id: el.propertyManagerSubjectId,
            name: el.propertyManagerName
          };
        });

        self.recipients = recItems.concat(custItems);

        self.ynTeamMembers = res[0].value.data;
        self.customers = res[1].value.data.$values;

        self.branches = [];
        self.customers.forEach(el => {
          self.branches.push({
            name: el.branchName,
            value: el.branchName
          });
        });

        if (type === "forward") {
          for (var i = 1; i < self.message.attachments.$values.length; i++) {
            let max = Math.max(...self.attachmentsObj.map(i => i.id));
            self.attachmentsObj.push({
              id: (max += 1),
              url: self.message.attachments.$values[i].fileUrl,
              fileId: self.message.attachments.$values[i].id
            });
          }
        } else {
          if (type === "reply")
            self.newMessage.sendTo.push(self.message.sender.id);
          else if (type === "replyAll") {
            self.newMessage.sendTo = self.message.recipients.$values.map(
              el => el.id
            );

            if (
              self.newMessage.sendTo.filter(el => el === self.message.sender.id)
                .length == 0
            )
              self.newMessage.sendTo.push(self.message.sender.id);
          }
        }

        self.formLoading = false;
      });
    },
    getPermissions(permissionsObj) {
      this.permissions = permissionsObj;

      this.sendToItems = [
        {
          name: "YN team member or customer",
          value: "YN team member or customer"
        },
        {
          name: "My YN Team and my customers",
          value: "My YN Team and my customers"
        },
        { name: "My YN Team", value: "My YN Team" },
        { name: "My customers", value: "My customers" }
      ];
      if (this.permissions.message.canSendByBranch) {
        this.sendToItems.push({
          name: "Send by branch",
          value: "Send by branch"
        });
      }
    },

    async getRecipientsPromise() {
      return this.$store.dispatch(GET_QUERY, {
        listName: API_EMPLOYEES
      });
    },

    async getRecipients() {
      this.loadingRecipients = true;
      // let comunityItems = dataModels.communityDetails;
      let self = this;
      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_EMPLOYEES
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            self.recipients = response.data;

            let recipient = self.recipients.find(
              ({ id }) => id === self.$route.query.recipient
            );
            self.newMessage.sendTo.push(recipient);
          } else {
            self.$snackbar.showMessage({
              content:
                response.data ||
                "Cannot receive recipients. Something went wrong!",
              color: "red"
            });
          }

          this.loadingRecipients = false;
        });
    },

    async getCustomersPromise() {
      return this.$store.dispatch(GET_QUERY, {
        listName: API_CUSTOMERS
      });
    },
    async getCustomers() {
      this.loadingCustomers = true;
      let self = this;
      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_CUSTOMERS
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            let items = response.data.$values;

            self.branches = [];
            items.forEach(el => {
              self.branches.push({
                name: el.branchName,
                value: el.branchName
              });
            });

            self.customers = items;
          } else {
            self.$snackbar.showMessage({
              content:
                response.data ||
                "Cannot get a list of branches. Something went wrong!",
              color: "red"
            });
          }

          self.loadingCustomers = false;
        });
    },
    setShowSendToLine() {
      this.showSendToLine = this.sendTo === "YN team member or customer";
      this.showSendByBranch = this.sendTo === "Send by branch";
    },
    sendMessage() {
      this.newMessage.isNew = true;
      this.newMessage.parentId = this.message.id;
      this.newMessage.isActionRequired =
        this.newMessage.isActionRequired == "1";

      switch (this.sendTo) {
        case "YN team member or customer":
          this.newMessage.recipients = this.newMessage.sendTo.map(el => {
            return { id: el };
          });
          break;
        case "My YN Team and my customers":
          this.newMessage.recipients = this.customers.map(el => {
            return { id: el };
          });
          break;
        case "My YN Team":
          this.newMessage.recipients = this.customers.map(el => {
            return { id: el };
          });
          break;
        case "My customers":
          this.newMessage.recipients = this.customers.map(el => {
            return { id: el };
          });
          break;
        case "Send by branch":
          this.newMessage.sendToBranch.forEach(el => {
            let ynRecipients = this.ynTeamMembers.filter(
              r =>
                r.hasAllBranchAccess ||
                r.branches.$values.filter(b => b.name == el).length > 0
            );

            this.newMessage.recipients = ynRecipients.map(r => {
              return { id: r.id };
            });
          });

          break;
      }

      this.newMessage.attachments = this.attachmentsObj
        .filter(i => i.url && i.url.length > 0)
        .map(i => {
          return { id: i.fileId, fileUrl: i.url };
        });
      this.newMessage.attachments = this.newMessage.attachments.filter(
        (item, index) => this.newMessage.attachments.indexOf(item) === index
      ); //remove duplicates

      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.requestProcessing = true;
      let self = this;
      this.$store
        .dispatch(POST_DATA, {
          listName: `${API_MESSAGES}`,
          data: this.newMessage
        })
        .then(response => {
          if (response.status >= 200 && response.status <= 204) {
            self.snackbarContent = "Message has been sent";

            self.$router.push({
              name: "MessageCenter"
            });
          } else {
            self.snackbarContent = response.data;
            self.snackbarColor = "red";
          }
          self.$snackbar.showMessage({
            content: self.snackbarContent,
            color: self.snackbarColor
          });

          self.requestProcessing = false;
        });

      // this.saveCustomer();
    },
    onMultipleAddFile(files) {
      for (var i = 1; i < files.length; i++) {
        let max = Math.max(...this.attachmentsObj.map(i => i.id));
        this.attachmentsObj.push({
          id: (max += 1),
          url: files[i].fileUrl,
          fileId: files[i].id
        });
      }
      let max = Math.max(...this.attachmentsObj.map(i => i.id));
      this.attachmentsObj.push({ id: (max += 1), url: null });
      this.$nextTick(() => files.forEach(f => this.onChange(f)));
    },
    onChange(file) {
      if (file.fileUrl) {
        var found = this.attachmentsObj.find(f => f.url == file.fileUrl);
        found.fileId = file.id;
      }
    },
    onDeleteFile(fileData) {
      let index = this.attachmentsObj.findIndex(f => f.id === fileData.index);
      this.attachmentsObj.splice(index, 1);
    },
    async getMessage() {
      // let comunityItems = dataModels.communityDetails;
      let self = this;
      this.$store
        .dispatch(GET_QUERY, {
          listName: `${API_MESSAGES}`,
          id: this.messageId
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            self.message = response.data;

            self.markMessageAsOpened();
          } else {
            self.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }

          self.loading = false;
          self.isLoading = false;
        });
    },
    async markMessageAsOpened() {
      let self = this;
      let url = `${API_MESSAGES}/${self.messageId}/opened`;
      this.$store.dispatch(POST_DATA, {
        listName: url
      });
    },
    formatDate(date) {
      return dateFormatter.formatEditDate(date);
    },
    formatDateUs(date) {
      return dateFormatter.formatDateUs(date);
    },
    capitalizeFirstLetter(string) {
      return stringFormatter.capitalizeFirstLetter(string);
    }
  },
  computed: {
    accept() {
      return "*/*";
    },
    extensions() {
      return "gif,jpg,jpeg,png,webp,mp4,mov,avi,mkv,mpg,vob,wmv,m4v,asf,srt,pdf,doc,docx";
    },
    sendToErrors() {
      const errors = [];
      if (!this.$v) return errors;
      if (!this.$v.newMessage.recipients.$dirty) return errors;
      !this.$v.newMessage.recipients.required && errors.push("Required.");
      !this.$v.newMessage.recipients.minLength && errors.push("Required.");
      return errors;
    },
    subjectErrors() {
      const errors = [];
      if (!this.$v) return errors;
      if (!this.$v.newMessage.subject.$dirty) return errors;
      !this.$v.newMessage.subject.required && errors.push("Required.");
      return errors;
    },
    bodyErrors() {
      const errors = [];
      if (!this.$v) return errors;
      if (!this.$v.newMessage.body.$dirty) return errors;
      !this.$v.newMessage.body.required && errors.push("Required.");
      return errors;
    }
  }
};
</script>
